import { createRouter, createWebHashHistory } from 'vue-router'

/**
 * 公开路由表
 */
const publicRoutes = [
  {
    path: '/',
    component: () => import('@/views/video/index')
  },
  {
    path: '/home',
    component: () => import('@/views/index/index')
  },
  {
    path: '/extends/:id',
    component: () => import('@/views/extends/index')
  },
  {
    path: '/register/:id',
    component: () => import('@/views/register/index')
  },
  {
    path: '/dashboard',
    component: () => import('@/views/dashboard/index')
  },
  {
    path: '/myname',
    component: () => import('@/views/myname/index')
  },
  {
    path: '/namedetail',
    component: () => import('@/views/namedetail/index')
  },
  {
    path: '/:error*', // /:error -> 匹配 /, /one, /one/two, /one/two/three, 等
    name: 'nofound',
    component: () => import('@/views/nofound/index')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes: publicRoutes
})

export default router
