const baseSize = 16
function setRem() {
  const scale = document.documentElement.clientWidth / 1920
  const fontSize =
    baseSize * Math.min(scale, 2) > 12 ? baseSize * Math.min(scale, 2) : 12
  document.documentElement.style.fontSize = fontSize + 'px'
}
setRem()
window.onresize = function () {
  setRem()
}
