import { createApp } from 'vue'
import App from './App.vue'
import dayjs from 'dayjs'
import router from './router'
import '@/lib/rem'
import { createPinia } from 'pinia'
import './styles/index.scss'
import 'animate.css/animate.min.css'
import 'element-plus/theme-chalk/display.css'
import 'element-plus/theme-chalk/el-message.css'
import 'element-plus/theme-chalk/el-message-box.css'
import 'element-plus/theme-chalk/dark/css-vars.css'
import './styles/css-vars.css'
import { List, PullRefresh, Loading, Popup } from 'vant'
import '@vant/touch-emulator'

import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import piniaPluginPersist from 'pinia-plugin-persist'
import { Buffer } from 'buffer'
global.Buffer = Buffer

createApp().config.globalProperties.$dayjs = dayjs

const store = createPinia()
store.use(piniaPluginPersist)

const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app
  .use(store)
  .use(router)
  .use(List)
  .use(PullRefresh)
  .use(Loading)
  .use(Popup)
  .mount('#app')
